<template>
  <div>
    <v-list class="transparent">
      <template v-for="(c, i) in clients">
        <v-list-item :key="'allClients-'+i">
          <v-list-item-icon class="mr-3">
            <v-avatar
                color="icono"
                size="30"
              >
                <span class="font-weight-bold white--text text-uppercase">
                  {{ c.cli_des | fisrtLetter }}
                </span>
              </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold blue-grey--text text-uppercase"
              v-text="`${c.cli_des}`"
            />
            <v-list-item-subtitle
              class="muted-text font-italic"
              v-text="c.telefonos"
            />
          </v-list-item-content>
          <v-list-item-action class="ml-1">
            <v-btn
              plain
              small
              icon
              class="px-0"
              @click="deleteCli(i)"
            >
              <v-icon size="18" color="blue-grey">mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="i < clients.length - 1"
          :key="i"
        />
      </template>
    </v-list>
    <div
      v-if="clients.length === 0"
      class="d-flex flex-column justify-center align-center fill-height py-5"
    >
      <v-icon size="126" color="blue-grey lighten-5">
        mdi-comment-account-outline
      </v-icon>
      <span class="blue-grey--text text--lighten-3 font-italic">No ha seleccionado Destinatarios</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalClientsSend',
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    // clients: {
    //   type: Array,
    //   default: () => ([]),
    // },
  },
  data() {
    return {
      clients: this.value,
    }
  },
  filters: {
    fisrtLetter(val) {
      return val.charAt(0)
    }
  },
  watch: {
    value(val, oldVal) {
      this.clients = val
    },
    clients(val, oldVal) {
      this.$emit('input', val)
    },
  },
  methods: {
    deleteCli(index) {
      this.clients.splice(index, 1)
    },
  }
}
</script>
